import type { Block as PortableTextBlock } from 'astro-portabletext/types';
import type { CompanyResult } from '../api/types';
import type { CategoryTagProps } from '../components/ui/CategoryTag';
import type { FormField } from '../components/ui/Form';
import { PAGE_DOCUMENT_TYPES, PAGE_TYPE_TO_PATH_FUNCTION } from '../constants';
import type { ValuesType } from '@digital-spiders/typescript-utils';

export type PageDocumentType = (typeof PAGE_DOCUMENT_TYPES)[number];

export type PageDocumentSlugOnly = {
  slug: {
    current: string;
  };
};

export type PageDocument = PageDocumentSlugOnly & {
  _type: PageDocumentType;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PageDocumentToPathFunction = ValuesType<typeof PAGE_TYPE_TO_PATH_FUNCTION>;

export type PageTypeToPathFunction = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [Key in (typeof PAGE_DOCUMENT_TYPES)[number]]: (pageDocument: any) => string;
};

export type InternalLink =
  | {
      pageReference: PageReferenceType;
      anchorLink: string;
    }
  | {
      pageReference: PageReferenceType;
      anchorLink?: never;
    }
  | {
      pageReference?: never;
      anchorLink: string;
    };

export type ExternalLink = {
  url: string;
};

export interface DownloadFileFormModalType {
  _id: string;
  supertitle?: string;
  title: string;
  slug: {
    current: string;
  };
  fileToDownloadAfterSubmit: {
    asset: {
      url: string;
      originalFilename: string;
    };
  };
  formFields: Array<FormField>;
  submitButtonText?: string;
  text: RawPortableText;
  successMessage: RawPortableText;
}

export type VersatileLink =
  | {
      pageReference?: never;
      anchorLink?: never;
      url: string;
      downloadFileFormModal?: never;
    }
  | {
      pageReference: PageDocument;
      anchorLink?: never;
      url?: never;
      downloadFileFormModal?: never;
    }
  | {
      pageReference?: never;
      anchorLink: string;
      url?: never;
      downloadFileFormModal?: never;
    }
  | {
      pageReference: PageDocument;
      anchorLink: string;
      url?: never;
      downloadFileFormModal?: never;
    }
  | {
      pageReference?: never;
      anchorLink?: never;
      url?: never;
      downloadFileFormModal: DownloadFileFormModalType;
    };

export type RawPortableText = Array<PortableTextBlock>;

export type BackgroundColorTheme = '#FFFFFF' | '#1a1a1a' | '#0069FF' | undefined;

export type PageReferenceType = PageDocumentSlugOnly & {
  _type: PageDocumentType;
};

export interface ImageType {
  asset: {
    url: string;
    metadata?: {
      lqip: string;
    };
  };
}

export interface SanityImageType {
  hotspot?: {
    x: number;
    y: number;
  };
  crop?: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
  asset: {
    _id: string;
    metadata?: {
      lqip: string;
    };
    altText?: string;
    url: string;
  };
}

export type ButtonLinkType = {
  title: string;
} & VersatileLink;

export type HeroType = {
  title?: string;
  subtitle?: string;
  text?: string | RawPortableText;
  button?: ButtonLinkType;
  mobileImage?: SanityImageType;
  image?: SanityImageType;
  imageType?: 'backgroundImage' | 'halfWidth';
  companyResults?: Array<CompanyResult>;
  showClientBrandsLogos?: boolean;
};

export type SingleEntry = {
  title: string;
} & VersatileLink;

export type SingleEntryWithSubtitle = SingleEntry & { subtitle?: string };

export interface NestedEntry {
  title: string;
  entries: Array<SingleEntryWithSubtitle>;
}

export type NavigationMenu = Array<SingleEntry | NestedEntry>;

export function isNestedEntry(entry: SingleEntry | NestedEntry): entry is NestedEntry {
  return 'entries' in entry;
}

export interface LocalizedSEOWebsite {
  title: string;
  shareTitle?: string;
  description: string;
  shareDescription?: string;
  image?: {
    asset?: {
      url: string;
    };
  };
}

export interface LocalizedSEO {
  title?: string;
  shareTitle?: string;
  description?: string;
  shareDescription?: string;
  image?: {
    asset?: {
      url: string;
    };
  };
  canonicalUrl?: string;
  noIndex?: boolean;
  noFollow?: boolean;
}

export interface CardType {
  image: SanityImageType;
  title: string;
  text?: string;
  category?: Pick<CategoryTagProps, 'title' | 'icon'>;
  link: VersatileLink;
  linkText?: string;
}
