import React, { useRef, useState } from 'react';

import { checkUrlsMatch, clsx } from '@digital-spiders/misc-utils';
import { ControlledMenu, MenuItem, useHover } from '@szhsin/react-menu';
import { RxChevronDown } from 'react-icons/rx';
import type { NestedEntry } from '../../types/types';
import { getUrlFromVersatileLink } from '../../utils/sanity';
import MenuEntry from './MenuEntry';
import * as styles from './NestedMenuEntry.module.scss';

export interface NestedMenuEntryProps {
  entry: NestedEntry;
  onClick?: React.MouseEventHandler<HTMLElement>;
  currentUrl?: string;
  className?: string;
}

const NestedMenuEntry = ({
  entry,
  onClick,
  currentUrl,
  className,
}: NestedMenuEntryProps): React.ReactElement => {
  const ref = useRef(null);
  const [isEntryOpen, setIsEntryOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isEntryOpen, setIsEntryOpen);

  return (
    <div className={clsx(className, styles.container)}>
      {/* MOBILE */}
      <div className={styles.subMenuWrapperMobile}>
        <button
          onClick={() => setIsEntryOpen(!isEntryOpen)}
          className={clsx(
            styles.menuLink,
            isEntryOpen && styles.entryOpen,
            currentUrl &&
              entry.entries.some(subEntry =>
                checkUrlsMatch(currentUrl, getUrlFromVersatileLink(subEntry)),
              ) &&
              styles.active,
          )}
        >
          {entry.title}
          <RxChevronDown className={styles.icon} role="none" />
        </button>
        {isEntryOpen &&
          entry.entries.map((subEntry, i) => (
            <MenuEntry entry={subEntry} key={i} onClick={onClick} withLeftArrow isSubEntry />
          ))}
      </div>

      {/* DESKTOP */}
      <button
        className={clsx(
          styles.subMenuWrapperDesktop,
          isEntryOpen && styles.entryOpen,
          styles.menuLink,
          currentUrl &&
            entry.entries.some(subEntry =>
              checkUrlsMatch(currentUrl, getUrlFromVersatileLink(subEntry)),
            ) &&
            styles.active,
        )}
        ref={ref}
        aria-expanded={isEntryOpen}
        aria-disabled="false"
        aria-controls={'panel_menu'}
        tabIndex={0}
        role="button"
        {...anchorProps}
      >
        {entry.title}
        <RxChevronDown className={styles.icon} aria-label="chevron-open-menu" role="img" />
      </button>

      <ControlledMenu
        {...hoverProps}
        menuClassName={styles.subMenuDesktop}
        onClose={() => setIsEntryOpen(false)}
        state={isEntryOpen ? 'open' : 'closed'}
        gap={16}
        anchorRef={ref}
      >
        {entry.entries.map((subEntry, i) => (
          <React.Fragment key={i}>
            <MenuItem className={styles.nestedItem}>
              <MenuEntry
                entry={subEntry}
                key={i}
                onClick={onClick}
                className={styles.desktopSubentry}
                withLeftArrow
                isSubEntry
              />
            </MenuItem>
            {i !== entry.entries.length - 1 && <div className={styles.divider}></div>}
          </React.Fragment>
        ))}
      </ControlledMenu>
    </div>
  );
};

export default NestedMenuEntry;
