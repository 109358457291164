import { clsx } from '@digital-spiders/misc-utils';
import React from 'react';

import { type NestedEntry, type SingleEntry, isNestedEntry } from '../../types/types';
import * as styles from './Menu.module.scss';
import MenuEntry from './MenuEntry';
import NestedMenuEntry from './NestedMenuEntry';

interface MenuProps {
  entries: Array<SingleEntry | NestedEntry>;
  currentUrl?: string;
  className?: string;
}

const Menu = ({ entries, currentUrl, className }: MenuProps): React.ReactElement => {
  return (
    <nav className={clsx(styles.menuDesktop, className)}>
      <div className={styles.container}>
        {entries.map((entry, i: number) =>
          isNestedEntry(entry) ? (
            <NestedMenuEntry entry={entry} key={i} currentUrl={currentUrl} />
          ) : (
            <MenuEntry entry={entry} key={i} currentUrl={currentUrl} />
          ),
        )}
      </div>
    </nav>
  );
};

export default Menu;
