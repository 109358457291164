/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useState } from 'react';

import { checkIsInternalUrl, clsx } from '@digital-spiders/misc-utils';
import { capitalize, sum } from '@digital-spiders/nodash';
import { useStore } from '@nanostores/react';
import { SITE_DOMAIN } from '../../constants';
import { $ctaParam } from '../../stores/ctaParamStore';
import { $noNavParam } from '../../stores/noNavParamStore';
import {
  isNestedEntry,
  type ButtonLinkType,
  type ImageType,
  type NestedEntry,
  type SingleEntry,
} from '../../types/types';
import { getUrlFromVersatileLink } from '../../utils/sanity';
import ButtonLink from './ButtonLink';
import DropDownMenu from './DropDownMenu';
import * as styles from './Header.module.scss';
import Menu from './Menu';
import MenuButton from './MenuButton';
import SmartLink from './SmartLink';

export interface HeaderProps {
  sticky?: boolean;
  logoImage: ImageType;
  projectName: string;
  entries: Array<SingleEntry | NestedEntry>;
  ctaButtonAnchor?: string;
  currentUrl?: string;
  ctaButton?: ButtonLinkType;
}

const Header = ({
  sticky,
  logoImage,
  projectName,
  entries,
  ctaButtonAnchor,
  currentUrl,
  ctaButton,
}: HeaderProps): React.ReactElement => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // NOTE: this could probably be replaced by calculating the menu size
  // automatically with javascript.
  const MENU_GAP_SIZE = 24; // spacing-medium
  const MENU_ENTRY_PADDING_SIZE = 8; // spacing-x-small
  const MENU_CHAR_SIZE = 10;
  const MENU_EXTERNAL_URL_ICON_SIZE = 20;
  // Small menus are shown from tablet vertical upwards
  const MEDIUM_MENU_MIN_SIZE = 500; // Fits only from table horizontal upwards
  const LARGE_MENU_MIN_SIZE = 700; // Fits only from laptop upwards
  const HUGE_MENU_MIN_SIZE = 800; // Doesn't fit anywhere

  // Calculates the menu size so we can inject corresponding classes
  // to hide the menu when it doesn't fit anymore
  const menuApproximateSize =
    entries.filter(
      entry =>
        !isNestedEntry(entry) && !checkIsInternalUrl(getUrlFromVersatileLink(entry), SITE_DOMAIN),
    ).length *
      MENU_EXTERNAL_URL_ICON_SIZE +
    sum(entries.map(({ title }) => title.length * MENU_CHAR_SIZE + MENU_ENTRY_PADDING_SIZE * 2)) +
    (entries.length - 1) * MENU_GAP_SIZE;
  const menuSizeCategory =
    menuApproximateSize >= HUGE_MENU_MIN_SIZE
      ? 'huge'
      : menuApproximateSize >= LARGE_MENU_MIN_SIZE
        ? 'large'
        : menuApproximateSize >= MEDIUM_MENU_MIN_SIZE
          ? 'medium'
          : 'small';

  const ctaButtonUrl =
    ctaButton &&
    (ctaButtonAnchor &&
    currentUrl?.replace(/\/+$/, '') === getUrlFromVersatileLink(ctaButton).replace(/\/+$/, '')
      ? '#' + ctaButtonAnchor.replace('#', '')
      : getUrlFromVersatileLink(ctaButton));

  const ctaParam = useStore($ctaParam);
  const noNavParam = useStore($noNavParam);
  const isNavHidden = noNavParam === 'default' || noNavParam === 'all';

  return (
    <>
      <header
        className={clsx(
          styles.header,
          styles['headerWithMenu' + capitalize(menuSizeCategory)],
          sticky && styles.stickyHeader,
        )}
        data-theme={'light'}
      >
        <div
          className={clsx(
            styles.headerContainer,
            ctaParam === 'none' && styles.noCta,
            isNavHidden && styles.noNav,
          )}
        >
          <div className={styles.logoWrapper}>
            <SmartLink
              to="/"
              className={styles.logoContainer}
              style={{
                pointerEvents: isNavHidden ? 'none' : 'auto',
              }}
            >
              <img src={logoImage.asset.url} alt={`${projectName} Logo`} width={67} height={30} />
              <div className={styles.visuallyHidden}>{projectName}</div>
            </SmartLink>
          </div>

          {!isNavHidden && (
            <Menu entries={entries} className={styles.desktopMenu} currentUrl={currentUrl}></Menu>
          )}

          {/* <div className={styles.linksContainer}> */}
          {ctaButton && ctaButtonUrl && ctaParam !== 'none' && (
            <div className={styles.ctaButton}>
              <ButtonLink to={{ url: ctaButtonUrl }}>{ctaButton.title}</ButtonLink>
            </div>
          )}

          {!isNavHidden && (
            <MenuButton
              className={styles.menuButton}
              isMenuOpen={isMenuOpen}
              onTouch={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
            ></MenuButton>
          )}
          {/* </div> */}
        </div>
      </header>
      <div className={styles.dropDownMenuWrapperWindow}>
        <div
          className={clsx(
            styles.dropDownMenuWrapper,
            isMenuOpen ? styles.menuOpen : styles.menuClosed,
          )}
        >
          <DropDownMenu
            entries={entries}
            ctaButtonText={ctaButton?.title}
            ctaButtonUrl={ctaButtonUrl}
            onEntryClick={() => {
              setIsMenuOpen(false);
            }}
            onCtaButtonClick={() => {
              setIsMenuOpen(false);
            }}
            currentUrl={currentUrl}
          ></DropDownMenu>
        </div>
      </div>
    </>
  );
};

export default Header;
